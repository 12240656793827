<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Rab Unit" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" :value="data.id_proyek" @input="onChangeProyek"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Properti Tipe *</label>
            <v-select :options="propertiTipes" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_properti_tipe"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">No. Rab *</label>
            <vs-input class="w-full" v-model="data.no_rab" placeholder="Generate Otomatis" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Tahun *</label>
            <vs-input class="w-full" type="number" v-model="data.tahun_rab"/>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Periode Awal *</label>
            <flat-pickr class="w-full" v-model="data.tgl_mulai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Periode Akhir *</label>
            <flat-pickr class="w-full" v-model="data.tgl_akhir"></flat-pickr>
          </div>
          <div class="vx-col sm:w-2/12 w-full">
            <label class="ml-1 text-xs">Revisi ke</label>
            <vs-input class="w-full" type="number" :value="0" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total</label>
            <v-money class="w-full" :value="total" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <vs-input class="w-full" label-placeholder="Keterangan" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider> Detail RAB </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="modalItemRabUnit.active = true" :disabled="!data.id_proyek" class="py-2 px-3 mr-2">Add</vs-button>
                  <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.details.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
          </div>
        </div>

        <vs-table :data="rowsDetailRab" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Item RAB</vs-th>
            <vs-th class="whitespace-no-wrap">Group 1</vs-th>
            <vs-th class="whitespace-no-wrap">Group 2</vs-th>
            <vs-th class="whitespace-no-wrap">Satuan *</vs-th>
            <vs-th class="whitespace-no-wrap">Harga *</vs-th>
            <vs-th class="whitespace-no-wrap">Volume *</vs-th>
            <vs-th class="whitespace-no-wrap">Subtotal</vs-th>
            <vs-th class="whitespace-no-wrap">Incl. Rumus</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>
                <div class="w-64">
                  <vx-input-group>
                    <vs-input placeholder="Item RAB" :value="item.nama_item_rab" readonly @click="chooseItemRab(item.uuid)"/>
                    <template slot="append">
                      <div class="append-text btn-addon">
                        <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="chooseItemRab(item.uuid)"/>
                      </div>
                    </template>
                  </vx-input-group>
                </div>
              </vs-td>
              <vs-td><vs-input class="w-48" type="text" v-model="item.group1_item_rab" readonly/></vs-td>
              <vs-td><vs-input class="w-48" type="text" v-model="item.group2_item_rab" readonly/></vs-td>
              <vs-td><vs-input class="w-48" type="text" v-model="item.satuan"/></vs-td>
              <vs-td><v-money class="w-48" type="text" v-model="item.harga_satuan"/></vs-td>
              <vs-td><vs-input class="w-32" type="text" v-model="item.volume"/></vs-td>
              <vs-td><v-money class="w-48 text-right" type="text" :value="item.harga_satuan * item.volume" readonly/></vs-td>
              <vs-td><vs-checkbox v-model="item.is_hpp_bangunan"></vs-checkbox></vs-td>
            </vs-tr>

            <!--footer-->
            <vs-tr v-if="rowsDetailRab.length > 0">
              <vs-td colspan="8" class="text-right"><v-money class="w-48 text-right" type="text" :value="total" disabled/></vs-td>
              <vs-td></vs-td>
            </vs-tr>

          </template>
        </vs-table>

        <div class="vx-row mt-6">
          <div class="vx-col w-full sm:w-6/12">
            <div class="float-left mt-4">
              <vs-checkbox v-model="data.isGenerateHppBangunan">Otomatis generate HPP bangunan saat simpan</vs-checkbox>
            </div>
          </div>
          <div class="vx-col w-full sm:w-6/12">
            <div class="float-right">
              <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
              <vs-button :disabled="isLoading" @click="save">
                <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                <span v-if="!isLoading">Simpan</span>
              </vs-button>
            </div>
          </div>
        </div>
      </div>

      <!--modal item rab unit-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Item RAB Unit"
                :active="modalItemRabUnit.active"
                v-on:update:active="modalItemRabUnit.active = $event">
        <ItemRabUnit :selectable="true" multi-select :disable-ids="itemRabIds" :isClearCheckedAfterEmit="false" :externalFilter="filterItemRabUnit" @selected="onSelectedModalItemRabUnit"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import RabUnitRepository from '@/repositories/proyek/rab-unit-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import ItemRabUnit from '@/views/pages/master/item-rab/ItemRab'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import vSelect from 'vue-select'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'RabUnitAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    flatPickr,
    ItemRabUnit,
    'v-select': vSelect,
    VMoney
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      search: '',
      modalItemRabUnit: {
        rowUuid: null, // uuid from this.data.details[n].uuid
        active: false
      },
      data: {
        id_proyek: null,
        id_properti_tipe: null,
        isGenerateHppBangunan: true,
        details: []
      },
      proyeks: [],
      propertiTipes: []
    }
  },
  computed: {
    total () {
      return _.sumBy(this.data.details, item => (item.harga_satuan * item.volume))
    },
    filterItemRabUnit () {
      return {
        id_proyek: this.data.id_proyek,
        id_item_group1: 3 // Unit
      }
    },
    itemRabIds () {
      return _.map(this.data.details, item => item.id_item_rab)
    },
    rowsDetailRab () {
      const search = this.search
      const items = this.data.details
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama_item_rab.toLowerCase().includes(search)
        })
      }
      return items
    }
  },
  methods: {
    onChangeProyek (value) {
      const vue = this
      const changeProyek = function () {
        vue.data.id_proyek = value
        vue.propertiTipes = []
        vue.data.id_properti_tipe = null
        vue.data.details = []
        vue.getPropertiTipeByProyek()
      }

      // confirm
      if (this.data.details.length > 0) {
        this.$vs.dialog({
          ...this.confirmOptionsClearRows(),
          accept: () => {
            changeProyek()
          }
        })
      } else {
        changeProyek()
      }
    },

    getPropertiTipeByProyek () {
      const idProyek = this.data.id_proyek
      PropertiTipeRepository.getAllByProyek(idProyek)
        .then(response => {
          this.propertiTipes = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      RabUnitRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseItemRab (rowUuid) {
      this.modalItemRabUnit.rowUuid = rowUuid
      this.modalItemRabUnit.active = true
    },

    onSelectedModalItemRabUnit (items) {
      _.each(items, item => this.addRow(item))
      this.modalItemRabUnit.active = false
    },

    addRow (item) {
      const row = {
        uuid: uuid(),
        id_item_rab: item.id,
        nama_item_rab: item.nama,
        group1_item_rab: item.group1,
        group2_item_rab: item.group2
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details.splice(index, 1)
    },

    clearRows () {
      this.$vs.dialog({
        ...this.confirmOptionsClearRows(),
        accept: () => {
          this.data.details = []
        }
      })
    },

    confirmOptionsClearRows () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Item RAB akan dihapus semua?',
        acceptText: 'Ya',
        cancelText: 'Batal'
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
